import React from 'react';
import locationHOC from '../../locationProvider/locationHOC'
import withDataHOC from '../../dataProvider/withDataHOC'
import ObjectHistoryTable from '../../history/objectHistory/ObjectHistoryTable'
import HistoryModalAction from './HistoryModalAction'
import {navigateToParametrized, getQueryParam, getQueryParamNumber} from '../../../lib/url';
import {QUERY_PARAMS} from '../../../constants/navigation'
import {GLOBAL_DATA} from '../../../constants/globalData'
import {Trans, t} from '@lingui/macro';
import deepEqual from 'deep-equal';
import { createFetchHistoryRecords } from '../../../backend/apiCalls';

/**
 * @fero
 */

class HistoryDisplay extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            history: null
        };
    }

    getFetchParams = (props) => {
        const {location} = props;
        return {
            class: getQueryParam(location, QUERY_PARAMS.HISTORY_CLASS),
            method: getQueryParam(location, QUERY_PARAMS.HISTORY_METHOD),
            at: getQueryParam(location, QUERY_PARAMS.HISTORY_AT),
            id_who: getQueryParamNumber(location, QUERY_PARAMS.HISTORY_WHO),
            limit: getQueryParamNumber(location, QUERY_PARAMS.HISTORY_LIMIT)
        };
    }

    componentDidMount() {
        this.fetchHistory();
    }
    
    componentDidUpdate(prevProps) {
        const params = this.getFetchParams(this.props);
        const prevParams = this.getFetchParams(prevProps);

        if(!deepEqual(params, prevParams))
            this.fetchHistory();
    }

    fetchHistory = () => {
        const fetchHandler = this.props[GLOBAL_DATA.FETCH_HANDLER];
        const params = this.getFetchParams(this.props);
        if(params.class != null)
        {
            fetchHandler(
                createFetchHistoryRecords(),
                params,
                (result) => {
                    this.setState({history: result});
                }
            )
        }
        else
        {
            this.setState({history: null});
        }
    }

    componentWillUnmount() {
        this.setState({history: []});
    }

    setLimit = (newLimit) => {
        const {location} = this.props;
        navigateToParametrized(location, null, {
            [QUERY_PARAMS.HISTORY_LIMIT]: newLimit,
        });
    };

    render() {
        const {location} = this.props;
        const {history} = this.state;
        const currentLimit = getQueryParamNumber(location, QUERY_PARAMS.HISTORY_LIMIT);
        if(history != null) 
        {
            return <ObjectHistoryTable
                objectHistory={history}
                limit={currentLimit}
                setLimit={this.setLimit}
                Actions={HistoryModalAction}
            />
        } 
        else 
        {
            return <div className="m-5 d-flex justify-content-center">
                <h1 className="d-inline color-disabled">
                    <Trans>Vyberte objekt a akciu...</Trans>
                </h1>
            </div>;
        }
    }

}

export default locationHOC(withDataHOC([GLOBAL_DATA.FETCH_HANDLER])(HistoryDisplay));